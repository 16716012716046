<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? $t('edit') : $t('add')}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      center
      width="50%">
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="上级权限" prop="parentId">
              <el-cascader
                ref="cascader"
                :options="permissions"
                v-model="form.parentId"
                :show-all-levels="false"
                :props="{ checkStrictly: true, emitPath: false }"
                clearable></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜单类型" prop="menuType">
              <el-select v-model="form.menuType">
                <el-option :value="'M'" label="目录"></el-option>
                <el-option :value="'C'" label="菜单"></el-option>
                <el-option :value="'F'" label="按钮"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜单名称" prop="menuName">
              <el-input clearable v-model="form.menuName" placeholder="起一个名字" maxlength="20"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="路由唯一键" prop="menuKey" v-if="form.menuType!=='F'">
              <el-input clearable v-model="form.menuKey" placeholder="路由唯一键：如'user'" maxlength="25"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.menuType!=='M'">
            <el-form-item label="权限标识" prop="perms">
              <el-input clearable v-model="form.perms" placeholder="起一个名字" maxlength="100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.menuType==='F'">
            <el-form-item label="组件">
              <el-input clearable v-model="form.component" placeholder="请输入组件" maxlength="20"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="链接地址" v-if="form.menuType==='C'">
              <el-input clearable v-model="form.path" placeholder="请输入链接地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.menuType==='M'">
            <el-form-item label="图标" prop="icon">
              <el-input clearable v-model="form.icon" placeholder="请输入图标地址" maxlength="100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="显示顺序" prop="sort">
              <el-input clearable v-model="form.sort" placeholder="请输入顺序数字" maxlength="5"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="状态" prop="visible">
              <el-select v-model="form.visible">
                <el-option :value="'0'" label="显示"></el-option>
                <el-option :value="'1'" label="隐藏"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      permissions: [],
      data: {},
    }
  },
  watch: {
    // eslint-disable-next-line
    'form.parentId'() {
      if (this.$refs.cascader) {
        this.$refs.cascader.dropDownVisible = false
      }
    },
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.clearValidate()
      this.form = new AddDialogClass('form')
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            this.$http.put(apis.createWebsiteWbMenu, { ...this.form }).then((res) => {
              if (res.data.code === 0) {
                this.closeDialog()
              }
            })
          } else {
            this.$http.post(apis.createWebsiteWbMenu, { ...this.form }).then((res) => {
              if (res.data.code === 0) {
                this.closeDialog()
              }
            })
          }
        }
      })
      console.log(`我执行了点确定的业务需求`)
      // 调用关闭方法，不要再写一次
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.form = Object.assign(this.form, { ...data, parentId: String(data.parentId) })
    },
    // 获取层级
    getPermissions() {
      this.permissions = [
        {
          label: '无',
          value: '0',
        },
      ]
      this.$http.get(apis.permission_list).then((res) => {
        this.buildtree(res.data.rows, this.permissions, 0)
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          const child = {
            key: String(item.menuId),
            value: String(item.menuId),
            label: item.menuName,
            children: [],
          }
          this.buildtree(list, child.children, item.menuId)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
  },
}
</script>
