/*
 * @Author: 安林娜
 * @LastEditors: 安林娜
 */

class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.menuId = ''
      this.parentId = '0'
      this.menuType = ''
      this.menuName = ''
      this.menuKey = ''
      this.perms = ''
      this.component = ''
      this.icon = ''
      this.sort = ''
      this.visible = ''
      this.path = ''
    } else if (type === 'rule') {
      this.parentId = [
        {
          required: true,
          message: '请选择上级权限',
          trigger: 'blur',
        },
      ]
      this.menuType = [
        {
          required: true,
          message: '请选择类型',
          trigger: 'blur',
        },
      ]
      this.menuType = [
        {
          required: true,
          message: '请选择类型',
          trigger: 'blur',
        },
      ]
      this.menuName = [
        {
          required: true,
          message: '请输入菜单名称',
          trigger: 'blur',
        },
      ]
      this.menuKey = [
        {
          required: true,
          message: '请输入动态菜单唯一键',
          trigger: 'blur',
        },
      ]
      this.perms = [
        {
          required: true,
          message: '请输入权限标识',
          trigger: 'blur',
        },
      ]
      this.icon = [
        {
          required: true,
          message: '请输入图标',
          trigger: 'blur',
        },
      ]
      this.sort = [
        {
          required: true,
          message: '请输入顺序数字',
          trigger: 'blur',
        },
      ]
      this.visible = [
        {
          required: true,
          message: '请选择状态',
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
